import styled from '@emotion/styled';
import MuiDialog from '@mui/material/Dialog';

import { BREAKPOINTS } from '../../variables';

export const Dialog = styled(MuiDialog)<{ allowOverflowVisibility?: boolean }>`
  ${({ allowOverflowVisibility }) =>
    allowOverflowVisibility &&
    `@media only screen and (min-width: ${BREAKPOINTS.sm}) {
      & .MuiDialogContent-root,
      & .MuiPaper-root {
        overflow: visible !important;
      }
    }`}

  & > .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }

  & > .MuiDialog-container > .MuiDialog-paper:not(.MuiDialog-paperFullScreen) {
    border-radius: 1rem !important;
    margin: 2rem auto 2rem auto !important;
  }
`;
